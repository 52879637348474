<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title>
            <span class="headline">{{ challenge.name }} challenge</span>
          </v-card-title>
          <v-img class="white--text align-end" height="200px" src="https://cdn.vuetifyjs.com/images/cards/docks.jpg">
          </v-img>
          <v-card-text>
            {{ challenge.description }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="orange" @click="dialog = true" :loading="loading">
              Topup
            </v-btn>

            <v-btn v-if="challenge.status == 'completed'" color="orange" text>
              Withdraw
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2"> Topup to {{ challenge.name }} challenge </v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field v-model="paymentData.amount" label="How much are you going to save?" outlined dense placeholder="Amount"></v-text-field>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="payViaService" :loading="loading">
              Deposit
            </v-btn>
            <v-btn type="reset" outlined class="mx-2" @click="dialog = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
        <v-row>
      <v-col cols="12" md="6" class="mx-auto">
        <v-snackbar timeout="-1" v-model="snackbar">
          <template >
            <span>Payment of {{flutterwave_response.currency}} {{flutterwave_response.amount}} {{flutterwave_response.status}}</span>
          </template>
          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      snackbar: false,
      dialog: false,
      loading: false,
      challenge: {},
      id: this.$route.params.id,
      paymentData: {
        tx_ref: '',
        amount: 5000,
        currency: 'UGX',
        payment_options: 'card,mobilemoneyuganda',
        redirect_url: '',

        // meta: {
        //   'counsumer_id': '7898',
        //   'consumer_mac': 'kjs9s8ss7dd'
        // },
        customer: {
          name: '',
          email: '',
          phone_number: '',
        },
        customizations: {
          title: 'Payment for challenge',
          description: '',
          logo: 'https://pesasave.app/wp-content/uploads/2022/03/pesasave_logo.png',
        },
        callback: this.makePaymentCallback,
        onclose: this.closedPaymentModal,
      },
      flutterwave_response: {},
    }
  },
  mounted() {
    this.getChallenge()
    const user = this.$auth.user()
    this.paymentData.customer.phone_number = user.phone
    this.paymentData.customer.email = user.email
    this.paymentData.customer.name = user.name
  },
  methods: {
    getChallenge() {
      this.$http.get(`/challenges/${this.id}`).then(response => {
        this.challenge = response.data.data
      })
    },
    payViaService() {
      this.loading = true
      this.dialog = false

      // Get reference number from api
      this.$http
        .post(`/wallets/${this.challenge.wallet.id}/deposit`, {
          amount: 500,
          currency: 'UGX',
          redirect_url: '',
        })
        .then(response => {
          this.paymentData.tx_ref = response.data.data.reference
          this.payWithFlutterwave(this.paymentData)
        })
    },
    makePaymentCallback(response) {
      console.log('Pay', response)
      this.loading = false
      this.flutterwave_response = response
      this.snackbar = true
      this.dialog = false
    },
    closedPaymentModal() {
      console.log('payment is closed')
      this.loading = false
    },
  },
}
</script>

<style></style>
